body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.4rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}
h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.4rem;
}
h3 {
  font-size: 1.8rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.4rem;
}
h6 {
  font-size: 1rem;
}
.font-bold {
  font-weight: bold;
}
.font-weight-600 {
  font-weight: 600;
}

/**text colors*/
.text-primary {
  color: var(--primary-color);
}
.text-secondary {
  color: var(--secondary-color);
}
.text-light {
  color: white;
}
.text-dark {
  color: black;
}
.text-success {
  color: var(--green-600);
}
.text-danger {
  color: var(--red-600);
}
/**.error, .error p,
.error label {
    color: var(--red-700) !important;
}*/

/**text align*/
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}

/** text decoration */
.text-underline {
  text-decoration: underline;
}

a {
  cursor: pointer;
  color: var(--secondary-color);
}
