.pointer {
  cursor: pointer;
}

/**Margins*/
.margin-0 {
  margin: 0;
}
.margin-20 {
  margin: 2rem !important;
}
.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 0.5rem;
}
.mt-10 {
  margin-top: 1rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-30 {
  margin-top: 3rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-20 {
  margin-bottom: 2rem !important;
}
.mb-10 {
  margin-bottom: 1rem !important;
}

/**Paddings*/
.padding-0 {
  padding: 0;
}
.padding-20 {
  padding: 2rem;
}

/**Position*/
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

/**Width*/
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
.max-width-90 {
  max-width: 90% !important;
}
.width-min-content {
  width: min-content;
}

/** background color */
.background-primary {
  background-color: var(--primary-color);
}
.background-secondary {
  background-color: var(--secondary-color);
}
.background-gray {
  background-color: var(--gray-600);
}

//hover
.hover {
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
