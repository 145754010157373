.button-icon {
  font-size: 1.8rem;
}


/** label + element in a form */
.field-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  label {
    margin-left: 0.5rem;
  }
}
