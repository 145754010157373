/* displays */
.flex {
  display: flex;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-direction-row {
  flex-direction: row;
}
.wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* display-flex */
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
.flex-center {
  justify-content: center;
}
.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
}
.justify-end {
  justify-content: end;
}

/* align-items */
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.align-auto {
  align-self: auto;
}
.align-start {
  align-self: flex-start;
}

/* gap */
.gap-5 {
  gap: 0.5rem;
}
.gap-10 {
  gap: 1rem;
}
.gap-20 {
  gap: 2rem;
}
.gap-40 {
  gap: 4rem;
}

/**ROW layout */
.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.col-12 {
  flex: 0 0 100%; /* 100% width*/
  padding: 1rem;
}

.col-8 {
  flex: 0 0 66.66%; /* 2/3 width */
  padding: 1rem;
}

.col-6 {
  flex: 0 0 50%; /* 50% width */
  padding: 1rem;
}

.col-4 {
  flex: 0 0 33.33%; /* 1/3 width */
  padding: 1rem;
}

.col-3 {
  flex: 0 0 25%; /* 1/4 width */
  padding: 1rem;
}

@media screen and (max-width: 1400px) {
  .col-s {
    flex: 0 0 25% !important;
  }
  .col-m {
    flex: 0 0 33.33% !important;
  }
  .col-l {
    flex: 0 0 50% !important; /* 50% width */
  }
}

@media screen and (max-width: 1250px) {
  .col-xl {
    flex: 0 0 100% !important; /* 100% width */
  }
}

@media screen and (max-width: 1000px) {
  .col-s {
    flex: 0 0 33.33% !important;
  }
}

@media screen and (max-width: 750px) {
  .col-l {
    flex: 0 0 100% !important; /* 100% width */
  }
  .col-s {
    flex: 0 0 50% !important;
  }
}

@media screen and (max-width: 580px) {
  .col-s {
    flex: 0 0 100% !important;
  }
}


/** GRID */
.grid {
  width: 100%;
  display: grid;
  gap: 2rem;
}

.grid-2, .grid-3 {
  grid-template-columns: repeat(1);
}

@media screen and (min-width: 900px) {
  .grid-2, .grid-3 {
    grid-template-columns: repeat(2, calc(50% - 1rem));
  }
}

@media screen and (min-width: 1024px) {
  .grid-3 {
    grid-template-columns: repeat(3, calc(33.33% - 1.3rem));
  }
}
