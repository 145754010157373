/** Imports */
@import './1_base/typography';
@import './1_base/utilities';
@import './2_layout/layout';
@import './4_pages/auth_page';
@import './3_components/components';
@import 'boxicons';


body {
  padding: 0;
  margin: 0;
}
html {
    font-size: 62.5%;
}
html,
body {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
*::-webkit-scrollbar {
  width: 18px;
  height: 15px;
}

*::-webkit-scrollbar-track {
  background: white;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 20px;
  border: 6px solid white;
  background-color: #98a3a6;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #404647;
}
