.auth-page {
  background-color: var(--primary-color);
  height: calc(100vh - 6rem);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem !important;

  .background-logo {
    position: absolute;
    top: calc(10% - 3rem);
    left: 3rem;
    width: 50%;
    height: 80%;
    > img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      opacity: 0.2;
    }
  }

  .logo-container img {
    width: 80%;
    max-width: 150px;
  }
}
